<template>
  <v-row>
    <v-col class="mb-4">
      <h1 class="display-1 font-weight-bold mx-1 mb-3">Marketing Materials</h1>
      <p>Copy link to artists Doricmor public page</p>
      <p>Download or order marketing materials. (Flyers, catalog, business card etc)</p>
      <p>Post on social media accounts</p>
      <p>Direct mails, texts to people who have subscribed to you on Doricmor</p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {
  },
  async created () {
  },
  async mounted () {
  },
  methods: {
  },
  data: function () {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
